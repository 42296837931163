import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFeedComponent } from './common-feed.component';
import { CommentSectionComponent } from './comment-section/comment-section.component';

// modules
import { ShareModule } from '../../common/share/share.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router'
import { FeedDetailComponent } from './feed-detail/feed-detail.component';
import { PurchaseProductComponent } from './purchase-product/purchase-product.component';
import { AddressModalModule } from '../../common/address-modal/address-modal.module';
import { LikeDetailComponent } from './like-detail/like-detail.component';
import { DateAgoModule } from 'src/app/shared/pipe/date-ago/date-ago.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PickerComponent, PickerModule } from '@ctrl/ngx-emoji-mart';


@NgModule({
  declarations: [
    CommonFeedComponent,
    CommentSectionComponent,
    FeedDetailComponent,
    PurchaseProductComponent,
    LikeDetailComponent
  ],
  imports: [
    CommonModule,
    ShareModule,
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    FormsModule,
    RouterModule,
    AddressModalModule,
    DateAgoModule,
    PickerModule,
    SharedModule
    ],
  exports: [
    CommonFeedComponent,
    CommentSectionComponent,
    PurchaseProductComponent,
    FeedDetailComponent,
    LikeDetailComponent
  ],
})
export class CommonFeedModule { }
