import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddEditFeedComponent } from './add-edit-feed.component';

import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { AllContactsModule } from '../../all-contacts/all-contacts.module';

@NgModule({
  declarations: [
    AddEditFeedComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    AllContactsModule
  ],
  exports: [
    AddEditFeedComponent
  ],
  entryComponents:[
    AddEditFeedComponent
  ]
})
export class AddEditFeedModule { }
