export const SuccessErrorConst = {

    addCommentSuccess: 'Please add comment!',
    addedSuccess: 'Added Successfully!',
    updatedSuccess: 'Updated Successfully!',
    reportedSuccess: 'Reported Successfully!',
    blockedSuccess: 'Blocked Successfully!',
    unblockedSuccess: 'Unblocked Successfully!',
    deletedSuccess: 'Deleted Successfully!',
    otpResend: 'OTP resent successfully!'

}
