import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddEditEventComponent } from './add-edit-event.component';

import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { AllContactsModule } from '../../all-contacts/all-contacts.module';

import { AmazingTimePickerModule } from 'amazing-time-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [
    AddEditEventComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    // BsDatepickerModule.forRoot(),
    // ReactiveFormsModule,
    AllContactsModule,
    AmazingTimePickerModule
  ],
  exports: [
    AddEditEventComponent
  ],
  entryComponents: [
    AddEditEventComponent
  ]
})
export class AddEditEventModule { }
